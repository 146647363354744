import React, { useState, useEffect } from 'react';

const IpoDate = (props) => {
  const [ipoDate, setipoDate] = useState("Loading...");
  const [error, setError] = useState(null);

  const fetchData = async (search) => {
    try {
      const response = await fetch(`https://flask-api-finlabs-b778fe863ba1.herokuapp.com/Stock/${search}/ipodate`);
      if (!response.ok) {
        throw new Error('Failed to fetch stock information');
      }
      const data = await response.json();
     
      setipoDate(data);
      setError(null); // Reset error state on successful data fetch
    } catch (error) {
      console.error('Error fetching stock information:', error);
      setipoDate(null); // Set ipoDate to null on error
      setError(error.message || 'An error occurred while fetching data');
    }
  };

  useEffect(() => {
    fetchData(props.stock);
  }, [props.stock]);

  return (
    <div>
      <h2>IPO Date:</h2>
      <div>
        {error || ipoDate === null ? (
          <p>Not Applicable</p>
        ) : (
          <p>{ipoDate}</p>
        )}
      </div>
    </div>
  );
};

export default IpoDate;