import React, { useState } from "react";
import { confirmRegistration } from "./userPool"; // Import the correct function
import "../styles/Confirm.style.css";

const Confirm = () => {
    const [email, setEmail] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const onConfirm = (event) => {
        event.preventDefault();
        setError("");
        setSuccess("");

        if (!email || !confirmationCode) {
            setError("Email and Confirmation Code are required.");
            return;
        }

        confirmRegistration(email, confirmationCode, (err, data) => {
            if (err) {
                handleError(err);
            } else {
                setSuccess("Account confirmed successfully! You can now sign in.");
                console.log(data);
            }
        });
    };

    const handleError = (err) => {
        let errorMessage = "An error occurred during confirmation.";
        if (err.code) {
            switch (err.code) {
                case "CodeMismatchException":
                    errorMessage = "The confirmation code you provided is incorrect.";
                    break;
                case "ExpiredCodeException":
                    errorMessage = "The confirmation code has expired. Please request a new one.";
                    break;
                case "UserNotFoundException":
                    errorMessage = "No user found with this email.";
                    break;
                default:
                    errorMessage = err.message || errorMessage;
            }
        }
        setError(errorMessage);
    };

    return (
        <div className="confirm-container">
            <form onSubmit={onConfirm} className="confirm-form">
                <h2>Confirm Your Account</h2>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmationCode">Confirmation Code</label>
                    <input
                        type="text"
                        id="confirmationCode"
                        value={confirmationCode}
                        onChange={(event) => setConfirmationCode(event.target.value)}
                        required
                    />
                </div>

                <button type="submit" className="confirm-button">Confirm</button>
            </form>

            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
        </div>
    );
};

export default Confirm;
