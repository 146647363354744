import React, { useState } from "react";
import { useNavigate } from "react-router";
import AutoCompleteSearch from "./autocompletesearch"; // Adjust the path as necessary
import companyTickers from "../stocksearch/company_tickers.json";
import "../styles/searchbar.style.css";

const CombinedSearchBar = () => {
  const [search, setSearch] = useState("");
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate("/searchResult/" + search, { replace: true });
    }
  };

  const handleAutoCompleteClick = (selectedSuggestion) => {
    setSearch(selectedSuggestion);
    setShowAutoComplete(false);
    navigate("/searchResult/" + selectedSuggestion, { replace: true });
  };

  const handleBlur = () => {
    // Hide auto-complete suggestions when the input loses focus
    setTimeout(() => setShowAutoComplete(false), 100);
  };

  const change = (event) => {
    setSearch(event.target.value);
    setShowAutoComplete(true);
  };

  return (
    <div className="search-container">
      <div className="search-bar" onBlur={handleBlur}>
        <input
          type="text"
          placeholder="Search"
          id="search-bar-component"
          className="rounded"
          value={search}
          onChange={change}
          onKeyDown={handleKeyPress}
          onFocus={() => setShowAutoComplete(true)} // Show suggestions when the input gains focus
        />
        <button
          className={search.trim() ? "filled" : "empty"}
          onClick={() => navigate("/searchResult/" + search, { replace: true })}
        >
          Search
        </button>
      </div>
      {showAutoComplete && (
        <AutoCompleteSearch
          query={search}
          onSuggestionClick={handleAutoCompleteClick}
          companyTickers={companyTickers}
        />
      )}
    </div>
  );
};

export default CombinedSearchBar;
