import React, { useState, useContext } from 'react';
import useApi from '../hooks/useApiPortfolios'; // Adjust the import path as needed
import { AuthContext } from '../infrastructure/authContext';
import { getCurrentUserId } from '../infrastructure/userPool'; // Ensure this method is correctly implemented
import LoadingIcon from '../infrastructure/loadingicon'; // Import the loading icon
// import '../styles/CreatePortfolioForm.style.css'; // Import your CSS styles

const CreatePortfolioForm = ({ client }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { createPortfolio } = useApi();
  const [portfolioName, setPortfolioName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCreatePortfolio = async (event) => {
    event.preventDefault();
    
    if (!portfolioName) {
      setError('Portfolio name is required.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const response = await createPortfolio({
        userId,
        portfolioName,
        description,
        clientId: client.clientId // Pass clientId to associate with the portfolio
      });

      console.log('Portfolio created successfully:', response);
      // Optionally, update the UI or state to reflect the new portfolio
      setPortfolioName('');
      setDescription('');
    } catch (error) {
      console.error('Error creating portfolio:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-portfolio-form-container">
      <h3>Create a New Portfolio</h3>
      {loading && <LoadingIcon />}
      {error && <div className="error-message">{error}</div>}
      
      <form onSubmit={handleCreatePortfolio}>
        <label htmlFor="portfolioName">Portfolio Name:</label>
        <input
          id="portfolioName"
          type="text"
          value={portfolioName}
          onChange={(e) => setPortfolioName(e.target.value)}
        />
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button type="submit">Create Portfolio</button>
      </form>
    </div>
  );
};

export default CreatePortfolioForm;
