import React, { useState, useEffect } from 'react';
import "../styles/searchstatistics.style.css";
import LoadingIcon from '../infrastructure/loadingicon'; // Import the LoadingIcon component

const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

const fetchHistoricalPrices = async (ticker) => {
    try {
        const response = await fetch(`${BASE_API_URL}/Stock/${ticker}/price/1y`);
        const data = await response.json();
        console.log(data);
        return data;
    } catch (e) {
        console.log(e);
        return {};
    }
};

const calculateStatistics = (prices) => {
    const mean = prices.reduce((a, b) => a + b, 0) / prices.length;
    const variance = prices.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / prices.length;
    const stdDev = Math.sqrt(variance);
    
    return { mean, stdDev };
};

const StatisticsComponent = ({ ticker }) => {
    const [statistics, setStatistics] = useState({ mean: 0, stdDev: 0 });
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const getStatistics = async () => {
            setLoading(true); // Set loading to true before fetching data
            const priceData = await fetchHistoricalPrices(ticker);
            const prices = Object.values(priceData).map(entry => entry.Close);
            console.log(prices);
            console.log(priceData);
            
            if (prices.length > 0) {
                const stats = calculateStatistics(prices);
                setStatistics(stats);
            }
            setLoading(false); // Set loading to false after data is fetched
        };
        getStatistics();
    }, [ticker]);

    return (
        <div>
            {loading ? ( // Conditional rendering for loading state
                <LoadingIcon /> // Show loading icon while loading
            ) : (
                <StatisticTable statistics={statistics} /> // Show statistics when loaded
            )}
        </div>
    );
};

const StatisticTable = ({ statistics }) => {
    return (
        <div>
            <h2>Stock Statistics</h2>
            <table>
                <thead>
                    <tr>
                        <th>Statistic</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mean Price</td>
                        <td>{statistics.mean.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Standard Deviation</td>
                        <td>{statistics.stdDev.toFixed(2)}</td>
                    </tr>
                    {/* Add more statistics as needed */}
                </tbody>
            </table>
        </div>
    );
};

export default StatisticsComponent;
