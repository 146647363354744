import { useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../infrastructure/authContext';
import { getCurrentUserId } from '../infrastructure/userPool'; // Ensure this method is correctly implemented
import userPool from '../infrastructure/userPool';

const useApi = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const api = axios.create({
    baseURL: 'https://hyhmomyugh.execute-api.us-east-2.amazonaws.com/prod', // Replace with your API Gateway URL
  });

  useEffect(() => {
    const setAuthHeaders = async () => {
      if (isAuthenticated) {
        try {
          const userId = await getCurrentUserId(); // Ensure this returns a valid userId
          if (userId) {
            const user = userPool.getCurrentUser();
            if (user) {
              user.getSession((err, session) => {
                if (err) {
                  console.error('Error getting session:', err);
                } else {
                  api.defaults.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
                  api.defaults.headers['x-user-id'] = userId; // Ensure correct header casing
                }
              });
            } else {
              console.error('User is not available in userPool');
            }
          } else {
            console.error('User ID is not available.');
          }
        } catch (error) {
          console.error('Error getting user ID:', error);
        }
      }
    };

    setAuthHeaders();
  }, [isAuthenticated]);

  const createPortfolio = async (portfolioName) => {
    console.log("This is create portfolio");
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      console.log('Creating portfolio for user:', userId); // Debugging user ID
      console.log('Portfolio name:', portfolioName); // Debugging portfolio name

      const portfolio = {
        userId: userId,
        portfolioName: portfolioName,
        description: JSON.stringify([]) // Initialize with an empty array in JSON format
      };

      const response = await api.post('/portfolios', portfolio);

      console.log('Portfolio creation response:', response.data); // Debugging backend response
      return response.data;
    } catch (error) {
      console.error('Error creating portfolio:', error);
      throw error;
    }
  };

  const getPortfolios = async () => {
    console.log("calling get portfolios...");
    const userId = await getCurrentUserId();
    console.log('isAuthenticated:', isAuthenticated);
    console.log('userId:', userId);

    // Check authentication and userId
    if (!isAuthenticated) {
      console.log("Error with authentication");
      throw new Error('User is not authenticated.');
    }

    if (!userId) {
      console.log("Error with userID");
      throw new Error('User ID is missing.');
    }

    try {
      const response = await api.get(`/portfolios?userId=${userId}`);
      console.log("Response:", response.data);

      // Return the array of portfolios
      return response.data || []; // Return an empty array if no data is found
    } catch (error) {
      console.error('Error fetching portfolios:', error);
      throw error;
    }
  };

  const updatePortfolio = async (portfolioId, portfolio) => {
    console.log('Updating portfolio... ', portfolio, portfolio.items);
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const updatedPortfolio = {
        ...portfolio,
        name: portfolio.portfolioName,
        userId: userId,
        portfolioId: portfolioId,
        description: JSON.stringify(portfolio.items || []) // Convert items array to JSON string
      };

      const response = await api.put(`/portfolios`, updatedPortfolio);
      console.log('API.put Updating portfolio');

      return response.data;
    } catch (error) {
      console.error('Error updating portfolio:', error);
      throw error;
    }
  };

  const deletePortfolio = async (portfolioId) => {
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const response = await api.delete(`/portfolios`, {
        data: { userId, portfolioId } // Include userId and portfolioId in the request body for deletion if needed
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting portfolio:', error);
      throw error;
    }
  };

  return { createPortfolio, getPortfolios, updatePortfolio, deletePortfolio };
};

export default useApi;
