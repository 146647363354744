// import "../App.css";
import YieldComponent from "../components/rates/yieldchart";



// renders the rates page
function Rates() {
  return (
    <div className="Yields">
      <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9991647735545324"
     crossorigin="anonymous"></script>
      </head>
      <header className="App-header">
        <h1>Yield Curve Data</h1>
      </header>
      <div className="Charts"></div>
      <br />
      <div className="Chart-container">
        <div className="Charts">
        <div id="chart-area">
        <YieldComponent />
    
      


      
      
      
    </div>
          
        </div>
        <br />
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default Rates;