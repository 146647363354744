import React, { useState, useEffect, useContext } from "react";
import { usePortfolio } from "./portfoliocontext";
import useApi from '../hooks/useApiPortfolios';
import { AuthContext } from '../infrastructure/authContext';

const PortfolioList = () => {
  const { state, dispatch } = usePortfolio();
  const { deletePortfolio, getPortfolios } = useApi();
  const { isAuthenticated } = useContext(AuthContext);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(state.selectedPortfolio || "");

  useEffect(() => {
    // Sync selectedPortfolioId with state.selectedPortfolio
    setSelectedPortfolioId(state.selectedPortfolio);
  }, [state.selectedPortfolio]);

  const handleDeletePortfolio = async (portfolioId) => {
    if (isAuthenticated) {
      try {
        await deletePortfolio(portfolioId);

        const response = await getPortfolios();
        const portfolioMap = response.reduce((acc, portfolio) => {
          acc[portfolio.portfolioId] = {
            ...portfolio,
            items: Array.isArray(portfolio.items) ? portfolio.items : [], // Ensure items is an array
          };
          return acc;
        }, {});

        dispatch({ type: 'SET_PORTFOLIO', payload: portfolioMap });

        if (response.length > 0) {
          const newSelectedPortfolioId = response[0].portfolioId;
          setSelectedPortfolioId(newSelectedPortfolioId);
          dispatch({ type: 'SELECT_PORTFOLIO', payload: newSelectedPortfolioId });
        } else {
          setSelectedPortfolioId("");
          dispatch({ type: 'SELECT_PORTFOLIO', payload: null });
        }
      } catch (error) {
        console.error('Error deleting portfolio:', error);
      }
    } else {
      dispatch({ type: 'DELETE_PORTFOLIO', payload: portfolioId });
    }
  };

  const handleSelectPortfolio = (portfolioId) => {
    setSelectedPortfolioId(portfolioId);
    dispatch({ type: "SELECT_PORTFOLIO", payload: portfolioId });
  };

  return (
    <div>
      <h2>My Portfolios</h2>
      <select
        value={selectedPortfolioId}
        onChange={(e) => handleSelectPortfolio(e.target.value)}
      >
        <option value="" disabled>Select a portfolio</option>
        {Object.keys(state.portfolios).map((portfolioId) => (
          <option key={portfolioId} value={portfolioId}>
            {state.portfolios[portfolioId].portfolioName}
          </option>
        ))}
      </select>
      {selectedPortfolioId && selectedPortfolioId !== "default" && (
        <button onClick={() => handleDeletePortfolio(selectedPortfolioId)}>Delete</button>
      )}
    </div>
  );
};

export default PortfolioList;
