import { CognitoUserPool, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";

const poolData = {
    UserPoolId: "us-east-2_RvIENgCmS",
    ClientId: "7gahhtem0fpqnj31l94e2qk3dh",
};

const userPool = new CognitoUserPool(poolData);

export const confirmRegistration = (email, confirmationCode, callback) => {
    const userData = {
        Username: email,
        Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
            callback(err);
        } else {
            callback(null, result);
        }
    });
};

// Method to get current user ID from the session
export const getCurrentUserId = () => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            console.error('Error getting session:', err); // Debugging error
            reject(new Error('Unable to get user session.'));
          } else {
            try {
              const userId = session.getIdToken().payload.sub; // Get user ID from token
              if (userId) {
                console.log('User ID retrieved:', userId); // Debugging success
                resolve(userId);
                console.log("Successfully resolved.")
              } else {
                console.error('User ID is not found in session.');
                reject(new Error('User ID not found in session.'));
              }
            } catch (e) {
              console.error('Error parsing user ID:', e); // Debugging error
              reject(new Error('Error parsing user ID.'));
            }
          }
        });
      } else {
        console.error('No user is currently logged in.');
        reject(new Error('No user is currently logged in.'));
      }
    });
  };

export default userPool;
