import React, { useEffect, useState } from 'react';

const AccountDetails = () => {
  const [accountData, setAccountData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Hardcoded userId and accountId
  const userId = 'user-789';
    const accountId = 'account-abc123';

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        // Construct API URL with query parameters
        const response = await fetch(`https://fif0tninme.execute-api.us-east-2.amazonaws.com/prod/accounts?userId=${userId}&accountId=${accountId}`);  

        
        console.log('josh allen', response)

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setAccountData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // Fetch account details when component mounts
    fetchAccountDetails();
  }, [userId, accountId]);

  if (loading) {
    return <div>Loading account details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!accountData) {
    return <div>No account data found.</div>;
  }

  return (
    <div className="account-details">
      <h2>Account Details</h2>
      <p><strong>Account Name:</strong> {accountData.accountName.S}</p>
      <p><strong>Account Number:</strong> {accountData.accountNumber.S}</p>
      <p><strong>Account Status:</strong> {accountData.accountStatus.S}</p>
      <p><strong>Account Type:</strong> {accountData.accountType.S}</p>
      <p><strong>Balance:</strong> ${accountData.balance.M.total.N}</p>
      <p><strong>Brokerage Name:</strong> {accountData.brokerageName.S}</p>
      <p><strong>Currency:</strong> {accountData.currency.S}</p>

      <h3>Positions</h3>
      <ul>
        {accountData.positions.L.map((position, index) => (
          <li key={index}>
            <strong>Security ID:</strong> {position.M.securityId.S}, 
            <strong>Type:</strong> {position.M.securityType.S}, 
            <strong>Quantity:</strong> {position.M.quantity.N}, 
            <strong>Market Value:</strong> ${position.M.marketValue.N}
          </li>
        ))}
      </ul>

      <h3>Transactions</h3>
      <ul>
        {accountData.transactions.L.map((transaction, index) => (
          <li key={index}>
            <strong>Transaction ID:</strong> {transaction.M.transactionId.S}, 
            <strong>Date:</strong> {transaction.M.date.S}, 
            <strong>Type:</strong> {transaction.M.type.S}, 
            <strong>Amount:</strong> ${transaction.M.amount.N}, 
            <strong>Description:</strong> {transaction.M.description.S}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountDetails;
