// returns the footer for each page and includes datamarkets copywrite
const Footer = () => {
    return(
        <div id="footer">
            <footer>
            <div class="footer-content">
                <p>&copy; Global Finance Lab - All rights reserved.</p>
                <a href="https://InvestorInsight.org/">InvestorInsight.org</a>
                <br />
                
                
            </div>
            </footer>
            <br />
            <br />


        </div>
    )
}

export default Footer