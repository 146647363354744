import React, { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js';

// Register chart components
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

// Sample data that wealth advisors or clients might analyze
const clientData = [
  { portfolioValue: 100000, annualReturn: 0.07, risk: 0.15, income: 50000, expenseRatio: 0.02 },
  { portfolioValue: 200000, annualReturn: 0.06, risk: 0.12, income: 60000, expenseRatio: 0.018 },
  { portfolioValue: 150000, annualReturn: 0.08, risk: 0.18, income: 55000, expenseRatio: 0.021 },
  { portfolioValue: 250000, annualReturn: 0.05, risk: 0.1, income: 70000, expenseRatio: 0.015 },
  { portfolioValue: 120000, annualReturn: 0.065, risk: 0.14, income: 52000, expenseRatio: 0.019 },
  // Add more sample data as needed
];

// Dropdown options for the X and Y axes
const attributes = {
  portfolioValue: 'Portfolio Value ($)',
  annualReturn: 'Annual Return (%)',
  risk: 'Risk (%)',
  income: 'Income ($)',
  expenseRatio: 'Expense Ratio (%)'
};

const ScatterChartComponent = () => {
  // State for selected x and y axis
  const [xAxis, setXAxis] = useState('portfolioValue');
  const [yAxis, setYAxis] = useState('annualReturn');

  // Prepare the scatter chart data based on the selected x and y axes
  const chartData = {
    datasets: [
      {
        label: `${attributes[yAxis]} vs ${attributes[xAxis]}`,
        data: clientData.map(client => ({
          x: client[xAxis],
          y: client[yAxis]
        })),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        pointRadius: 6,
      }
    ]
  };

  // Chart options
  const chartOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: attributes[xAxis],
        },
        beginAtZero: false,
      },
      y: {
        title: {
          display: true,
          text: attributes[yAxis],
        },
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {/* Dropdown for selecting X-axis */}
        <div>
          <label htmlFor="x-axis-select">Select X-Axis: </label>
          <select
            id="x-axis-select"
            value={xAxis}
            onChange={(e) => setXAxis(e.target.value)}
          >
            {Object.keys(attributes).map((key) => (
              <option key={key} value={key}>
                {attributes[key]}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown for selecting Y-axis */}
        <div>
          <label htmlFor="y-axis-select">Select Y-Axis: </label>
          <select
            id="y-axis-select"
            value={yAxis}
            onChange={(e) => setYAxis(e.target.value)}
          >
            {Object.keys(attributes).map((key) => (
              <option key={key} value={key}>
                {attributes[key]}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Scatter chart */}
      <Scatter data={chartData} options={chartOptions} />
    </div>
  );
};

export default ScatterChartComponent;
