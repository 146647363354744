import react from "react"
import Signup from "../components/infrastructure/signup"

const Register = () => {
    return(

    <div>
        <h2>Register</h2>
        <Signup />
    </div>

    )
}

export default Register