import React from 'react';
import PortfolioList from './clientportfoliolist';
import NetWorth from './clientnetworth';
import ReportGeneration from './clientreport';
import IncomeAnalytics from './clientincomeanalytics';
import CreditComponent from './clientcredit';
import CreatePortfolioForm from '../portfolio/clientportfolio';
import AccountDetails from './accountdetails';
import ScatterChartComponent from './client-analytics/client-charts/clientscatterplotcustom';
import SankeyChart from './client-analytics/financial-profile/income-expense-sankey';

const ClientDashboard = ({ client }) => {
  return (
    <div className="client-dashboard">
      <h1>{client.clientName}'s Dashboard</h1>
      {/* <PortfolioList portfolios={client.portfolios} />
      <NetWorth assets={client.assets} liabilities={client.liabilities} />
      <ReportGeneration client={client} />
      */}
      <IncomeAnalytics income={client.income} /> 
      <CreditComponent creditScore={client.creditScore} /> 
      <CreatePortfolioForm client={client} />
    <div>
        <AccountDetails />
    </div>
    <div>
      <ScatterChartComponent />
    </div>
    <div>
      <SankeyChart />
    </div>
    </div>
  );
};

export default ClientDashboard;
