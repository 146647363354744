import React, { createContext, useReducer, useContext } from 'react';

const PortfolioContext = createContext();

const initialState = {
  portfolios: {},
  selectedPortfolio: null,
};

const portfolioReducer = (state = initialState, action) => {
  // console.log('Action:', action);
  // console.log('State before action:', state);
  
  switch (action.type) {
    case 'SET_PORTFOLIO':
      return {
        ...state,
        portfolios: action.payload,
      };

    case 'SELECT_PORTFOLIO':
      return {
        ...state,
        selectedPortfolio: action.payload,
      };

    case 'DELETE_FROM_PORTFOLIO':
      if (!state.selectedPortfolio) return state;
      const updatedItems = state.portfolios[state.selectedPortfolio].items.filter(
        (_, index) => index !== action.payload
      );
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [state.selectedPortfolio]: {
            ...state.portfolios[state.selectedPortfolio],
            items: updatedItems,
          },
        },
      };

    case 'ADD_TO_PORTFOLIO':
      if (!state.selectedPortfolio) return state;
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [state.selectedPortfolio]: {
            ...state.portfolios[state.selectedPortfolio],
            items: [...state.portfolios[state.selectedPortfolio].items, action.payload],
          },
        },
      };

    case 'UPDATE_SHARES':
      if (!state.selectedPortfolio) return state;
      const { index, shares } = action.payload;
      const updatedItemsWithShares = state.portfolios[state.selectedPortfolio].items.map((item, i) =>
        i === index ? { ...item, shares } : item
      );
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [state.selectedPortfolio]: {
            ...state.portfolios[state.selectedPortfolio],
            items: updatedItemsWithShares,
          },
        },
      };

    case 'CREATE_PORTFOLIO':
      if (state.portfolios[action.payload]) {
        return state; // Prevent creating a portfolio with an existing name
      }
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.payload]: { name: action.payload, items: [] },
        },
      };

    case 'DELETE_PORTFOLIO':
      const { [action.payload]: _, ...remainingPortfolios } = state.portfolios;
      const newSelectedPortfolio = state.selectedPortfolio === action.payload
        ? Object.keys(remainingPortfolios)[0] || null
        : state.selectedPortfolio;
      return {
        ...state,
        portfolios: remainingPortfolios,
        selectedPortfolio: newSelectedPortfolio,
      };

    case 'SWITCH_PORTFOLIO':
      return {
        ...state,
        selectedPortfolio: action.payload,
      };

    default:
      return state;
  }
};

export const PortfolioProvider = ({ children }) => {
  const [state, dispatch] = useReducer(portfolioReducer, initialState);

  return (
    <PortfolioContext.Provider value={{ state, dispatch }}>
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolio = () => useContext(PortfolioContext);
