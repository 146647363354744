import React from 'react';

const CreditComponent = ({ creditScore }) => {
  return (
    <div className="credit-component">
      <h2>Credit Information</h2>
      {/* <p>Credit Score: {creditScore}</p> */}
      <p>Credit Score: 780</p>

    </div>
  );
};

export default CreditComponent;
