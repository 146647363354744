import React, { useState, useEffect, useContext } from 'react';
import useApiClients from '../../components/hooks/useApiClients';
import { AuthContext } from '../../components/infrastructure/authContext'; // Ensure this path is correct
import { getCurrentUserId } from '../../components/infrastructure/userPool';
import { Link } from 'react-router-dom';

import "../../components/styles/Clients.style.css"; // Import your styles
import LoadingIcon from '../../components/infrastructure/loadingicon'; // Import the loading icon
import Brokerages from '../brokerage-onboarding/Onboardingmenu';
import CreateManualClients from './CreateManualClient';

const Clients = () => {
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext to get authentication status
  const { createClientProfile, getClientProfiles, updateClientProfile, deleteClientProfile } = useApiClients();
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({
    clientName: '',
    dob: '',
    occupation: '',
    income: '',
    state: '',
    goals: ''
  });
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        if (!isAuthenticated) {
          setAuthError('User is not authenticated.');
          setLoading(false); // Ensure loading state is updated
          return;
        }

        const userId = await getCurrentUserId();
        if (!userId) {
          throw new Error('User ID is missing.');
        }

        const data = await getClientProfiles();
        setClients(data);
        setAuthError(null); // Clear any previous authentication error
      } catch (error) {
        console.error('Error fetching clients:', error);
        setAuthError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [isAuthenticated, getClientProfiles]);

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteClientProfile(clientId);
      setClients(clients.filter(client => client.clientId !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  if (loading) {
    return <LoadingIcon />; // Display the loading icon while loading
  }

  if (authError) {
    return <div>{authError}</div>; // Display authentication error
  }

  return (
    <div>
      <h1>Clients</h1>
      <div>
        <button>
          <Link to='/clients/onboarding'>Onboarding</Link>
          
        </button>
        
      <h2>Existing Clients</h2>
        {clients.map(client => (
          <div key={client.clientId}>
            <h3>
              <Link to={`/client/${client.clientId}`}>{client.clientName}</Link>
            </h3>
            <p>Date of Birth: {client.dob}</p>
            <p>Occupation: {client.occupation}</p>
            <p>Income: {client.income}</p>
            <p>State: {client.state}</p>
            <p>Goals: {client.goals}</p>
            <button onClick={() => handleDeleteClient(client.clientId)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;