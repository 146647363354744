import React, { createContext, useState, useEffect } from 'react';
import UserPool from './userPool';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err || !session.isValid()) {
            setIsAuthenticated(false);
            setCurrentUser(null);
          } else {
            setIsAuthenticated(true);
            setCurrentUser(user);
          }
        });
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
      }
    };

    checkAuth();
  }, []);

  const signIn = (user) => {
    setIsAuthenticated(true);
    setCurrentUser(user);
  };

  const signOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, currentUser, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
