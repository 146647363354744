import SearchBar from "../components/infrastructure/search";

// renders the 404 page if page searched for cannot be found.
const NoPage = () => {
    return (

      <div>
        <SearchBar />
        <br></br>
        <h1>404</h1>;
      </div>
    )
  };
  
  export default NoPage;
  