import Brokerages from "../brokerage-onboarding/Onboardingmenu"
import CreateManualClients from "./CreateManualClient"

const ClientOnboarding = () => {
    return(
            <div>
              <h1>Clients</h1>
              <div>
                <h3>Onboarding</h3>
                <p>Automated Solutions:</p>
                <Brokerages />
                <p>Manual Solution:</p>
                <CreateManualClients />
              </div>
              </div>
              
                )
    
}

export default ClientOnboarding