import { useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../infrastructure/authContext';
import { getCurrentUserId } from '../infrastructure/userPool';
import userPool from '../infrastructure/userPool';
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const useApiClients = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const api = axios.create({
    baseURL: 'https://m5ltxozfrk.execute-api.us-east-2.amazonaws.com/prod', // Replace with your API Gateway URL
  });

  useEffect(() => {
    const setAuthHeaders = async () => {
      if (isAuthenticated) {
        try {
          const userId = await getCurrentUserId();
          console.log(userId);
          if (userId) {
            const user = userPool.getCurrentUser();
            if (user) {
              user.getSession((err, session) => {
                if (err) {
                  console.error('Error getting session:', err);
                } else {
                  api.defaults.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
                  api.defaults.headers['x-user-id'] = userId;
                }
              });
            } else {
              console.error('User is not available in userPool');
            }
          } else {
            console.error('User ID is not available.');
          }
        } catch (error) {
          console.error('Error getting user ID:', error);
        }
      }
    };

    setAuthHeaders();
  }, [isAuthenticated]);

  const createClientProfile = async (clientProfile) => {
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const clientId = uuidv4(); // Generate unique clientId
      const profile = { ...clientProfile, userId, clientId }; // Include clientId in profile
      console.log(profile);

      const response = await api.post('/clients', profile);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error creating client profile:', error);
      throw error;
    }
  };

  const getClientProfiles = async () => {
    console.log("Calling get client profiles...");
    const userId = await getCurrentUserId();
    console.log('isAuthenticated:', isAuthenticated);
    console.log('userId:', userId);

    // Check authentication and userId
    if (!isAuthenticated) {
      console.log("Error with authentication");
      throw new Error('User is not authenticated.');
    }

    if (!userId) {
      console.log("Error with userID");
      throw new Error('User ID is missing.');
    }

    try {
      const response = await api.get(`/clients?userId=${userId}`);
      console.log(response)
      console.log("Response:", response.data);

      // Return the array of client profiles
      return response.data || []; // Return an empty array if no data is found
    } catch (error) {
      console.error('Error fetching client profiles:', error);
      throw error;
    }
  };

  const updateClientProfile = async (clientId, clientProfile) => {
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const updatedProfile = { ...clientProfile, userId, clientId };
      const response = await api.put(`/clients`, updatedProfile);
      return response.data;
    } catch (error) {
      console.error('Error updating client profile:', error);
      throw error;
    }
  };

  const deleteClientProfile = async (clientId) => {
    try {
      const userId = await getCurrentUserId();
      if (!userId) {
        throw new Error('User ID is not available.');
      }

      const response = await api.delete(`/clients`, {
        data: { userId, clientId }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting client profile:', error);
      throw error;
    }
  };

  return { createClientProfile, getClientProfiles, updateClientProfile, deleteClientProfile };
};

export default useApiClients;
