import React from 'react';

const IncomeAnalytics = ({ income }) => {
  return (
    <div className="income-analytics">
      <h2>Income Analytics</h2>
      <p>Total Income: ${income}</p>
      {/* Additional income analysis can be added here */}
    </div>
  );
};

export default IncomeAnalytics;
