import React, { useState, useEffect } from 'react';
import { usePortfolio } from './portfoliocontext';
import useApi from '../hooks/useApiPortfolios';

const PortfolioFunctionButton = ({ addItem }) => {
  const { dispatch, state } = usePortfolio();
  const { getPortfolios, updatePortfolio } = useApi();
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (addItem) {
      // Check if the item is already in the selected portfolio
      if (state.selectedPortfolio && state.portfolios[state.selectedPortfolio]) {
        const isItemAlreadyAdded = state.portfolios[state.selectedPortfolio].items.some(item => item.ticker === addItem);
        setAdded(isItemAlreadyAdded);
      }
    }
  }, [addItem, state]);

  const addToPortfolio = async () => {
    if (!addItem) {
      console.error('addItem is undefined or missing:', addItem);
      return;
    }
    if (!state.selectedPortfolio || !state.portfolios[state.selectedPortfolio]) {
      console.error('No portfolio selected or portfolio does not exist.');
      return;
    }

    const selectedPortfolioId = state.selectedPortfolio;

    try {
      // Fetch the latest portfolio state from AWS
      const portfolios = await getPortfolios();
      const selectedPortfolio = portfolios.find(portfolio => portfolio.portfolioId === selectedPortfolioId);

      console.log('Fetched portfolios:', portfolios);
      console.log('Selected portfolio:', selectedPortfolio);

      if (!selectedPortfolio) {
        console.error('Selected portfolio does not exist in AWS.');
        return;
      }

      console.log(selectedPortfolio.portfolioName, "Portfolio Name");
      console.log(selectedPortfolio, "Selected Portfolio");

      // Parse existing items from description
      const existingItems = selectedPortfolio.description ? JSON.parse(selectedPortfolio.description) : [];
      const updatedItems = [
        ...existingItems,
        { ticker: addItem, shares: 0 } // Set initial shares to 0
      ];

      console.log('Updated items:', updatedItems);
      console.log('Stringified updated items:', JSON.stringify(updatedItems));

      // Update the portfolio in AWS
      await updatePortfolio(selectedPortfolioId, {
        portfolioName: selectedPortfolio.portfolioName, // Keep the portfolio name
        items: updatedItems // Pass the updated items array
      });

      console.log('Portfolio updated in AWS successfully.');

      // Dispatch the new item to the state
      dispatch({
        type: 'SET_PORTFOLIO',
        payload: {
          ...state.portfolios,
          [selectedPortfolioId]: {
            ...state.portfolios[selectedPortfolioId],
            portfolioName: selectedPortfolio.portfolioName,
            items: updatedItems // Update items in the local state
          }
        }
      });

      setAdded(true);
      console.log('Added to portfolio and updated in AWS:', { ticker: addItem, shares: 0 });
    } catch (error) {
      console.error('Error updating portfolio:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <div className="portfolio-function-search">
        <button className="portfolio-function-button" onClick={addToPortfolio} disabled={added}>
          {added ? 'Added' : 'Add to Portfolio'}
        </button>
      </div>
    </div>
  );
};

export default PortfolioFunctionButton;
