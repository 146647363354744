import react from "react"
import Signin from "../components/infrastructure/signin"

const Login = () => {
    return(

    <div>
        <h2>Login/Register</h2>
        <Signin />
        <div>
            <br></br>
            <p>Not a member? </p>
            <p>Register here: <a href="/signup">Sign Up</a></p>
        </div>
    </div>

    )
}

export default Login