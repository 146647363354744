import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import "../styles/yieldchart.css";

import YieldAppDescription from './yieldappdescription';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function YieldComponent() {
    const [selectedDate, setSelectedDate] = useState(getLatestWeekday()); // Default to the latest weekday
    const [yieldsData, setYieldsData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchYieldData(date) {
            try {
                setLoading(true);
                const formattedDate = formatDate(date); // Format date as 'mm-dd-yyyy'
                const response = await fetch(`https://2s6tchd4n1.execute-api.us-east-2.amazonaws.com/treasury/yields/${formattedDate}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch yield data');
                }
                console.log(response)
                const responseData = await response.json();
                const parsedBody = JSON.parse(responseData.yields);
                console.log(parsedBody)
                setYieldsData(parsedBody);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching yield data:', error);
                setLoading(false);
            }
        }

        fetchYieldData(selectedDate);
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // Function to get the latest weekday before today's date
    function getLatestWeekday() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        let latestWeekday = new Date(today);
        // Loop backward to find the latest weekday
        while (latestWeekday.getDay() === 0 || latestWeekday.getDay() === 6) {
            latestWeekday.setDate(latestWeekday.getDate() - 1);
        }
        return latestWeekday;
    }

    // Function to format date as 'mm-dd-yyyy'
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}-${day}-${year}`;
    };

    // Function to process yield data for chart
    const setdata = (data) => {
        try {
            // Parse the yield data to construct the Line chart data object
            const labels = Object.keys(data);
            const values = Object.values(data);
            const datasets = [
                {
                    label: 'Yields',
                    data: values,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ];
            return {
                labels: labels,
                datasets: datasets
            };
        } catch (error) {
            console.error("Error processing yield data:", error);
            return {
                labels: [],
                datasets: []
            };
        }
    };

    // Function to disable weekends in the DatePicker
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6; // Return true for weekdays (0 = Sunday, 6 = Saturday)
    };

    return (
        <div>
            <label htmlFor="datePicker">Select a weekday:</label>
            <DatePicker
                id="datePicker"
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Select a date"
                className="form-control"
                filterDate={isWeekday} // Disable weekends
                maxDate={new Date()} // Disable future dates
            />
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <FaSpinner className="spinner" />
                </div>
            ) : (
                <div>
                    {Object.keys(yieldsData).length > 0 ? (
                        <>
                            <h3>Yield Chart for {selectedDate.toLocaleDateString()}</h3>
                            <div>
                                <p>
                                    <Line data={setdata(yieldsData)} />
                                </p>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Duration</th>
                                        <th>Yield</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(yieldsData).map(([duration, yieldValue], index) => (
                                        <tr key={index}>
                                            <td>{duration}</td>
                                            <td>{yieldValue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <p>No yield data available for the selected date.</p>
                    )}
                    <YieldAppDescription />
                </div>
            )}
        </div>
    );
}

export default YieldComponent;
