import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Rates from './pages/Rates';
import Home from './pages/Home';
import Navbar from './pages/Navbar';
import NoPage from './pages/NoPage';
import SearchResult from './pages/SearchResult';
import Portfolio from './pages/Portfolio';
import { PortfolioProvider } from './components/portfolio/portfoliocontext';
import Register from './pages/Register';
import Login from './pages/Login';
import { AuthProvider } from './components/infrastructure/authContext';
import Clients from './pages/clients/Clients';
import ClientResults from './pages/clients/ClientResults';
import FrontPage from './pages/FrontPage';
import ClientOnboarding from './pages/clients/ClientOnboarding';
import SchwabOnboarding from './pages/brokerage-onboarding/Onboarding-Schwab';
import Fidelitynboarding from './pages/brokerage-onboarding/Onboarding-Fidelity';
import EtradeOnboarding from './pages/brokerage-onboarding/Onboarding-ETrade';
import TDAmeritradeOnboarding from './pages/brokerage-onboarding/Onboarding-TDAmeritrade';
import RobinhoodOnboarding from './pages/brokerage-onboarding/Onboarding-Robinhood';
import InteractiveBrokersOnboarding from './pages/brokerage-onboarding/Onboarding-InteractiveBrokers';
import ClientPage from './pages/clients/ClientPage';
import ClientAnalyticsDashbaord from './pages/clients/ClientAnalytics';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/rates" element={<Rates />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/searchResult/:search" element={<SearchResult />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/login" element={<Login />} />




        <Route path='/clientpage' element={<ClientPage />} />
        <Route path='/clients/analytics' element={<ClientAnalyticsDashbaord /> } />
        <Route path='/clientpage/clients' element={<Clients />} />
        <Route path="/clients/onboarding" element={<ClientOnboarding />} />
        <Route path="/client/:clientId" element={<ClientResults />} />

        <Route path="/broker/schwab-onboarding" element={<SchwabOnboarding />} />
        <Route path="/broker/fidelity-onboarding" element={<Fidelitynboarding />} />
        <Route path="/broker/etrade-onboarding" element={<EtradeOnboarding />} />
        <Route path="/broker/tdameritrade-onboarding" element={<TDAmeritradeOnboarding />} />
        <Route path="/broker/robinhood-onboarding" element={<RobinhoodOnboarding />} />
        <Route path="/broker/interactivebrokers-onboarding" element={<InteractiveBrokersOnboarding />} />






        <Route path="*" element={<NoPage />} />


      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <PortfolioProvider>
        <App />
      </PortfolioProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
