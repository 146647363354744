import React, { useState, useContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from './userPool'; // Import your CognitoUserPool instance
import { AuthContext } from './authContext';

const SignIn = () => {
  const { signIn } = useContext(AuthContext); // Use the signIn function from context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        setSuccess('Sign-in successful!');
        signIn(); // This should update the AuthContext
        console.log(result);
        // Consider using a callback or redirect to another page
      },
      onFailure: (err) => {
        setError(err.message || 'An error occurred during sign-in.');
      },
    });
  };

  return (
    <div className="signin-container">
      <form onSubmit={onSubmit} className="signin-form">
        <h2>Sign In</h2>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </div>

        <button type="submit" className="signin-button">Sign In</button>
      </form>

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
    </div>
  );
};

export default SignIn;
