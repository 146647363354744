import React, { useEffect, useState } from 'react';
import useApi from '../hooks/useApiPortfolios';
import { usePortfolio } from '../portfolio/portfoliocontext';

const PortfolioItems = () => {
  const { state, dispatch } = usePortfolio();
  const { getPortfolios, updatePortfolio } = useApi();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchPortfolioItems = async () => {
      if (state.selectedPortfolio) {
        try {
          const portfolios = await getPortfolios();
          const selectedPortfolio = portfolios.find(
            portfolio => portfolio.portfolioId === state.selectedPortfolio
          );
          if (selectedPortfolio) {
            const portfolioItems = JSON.parse(selectedPortfolio.description);
            setItems(portfolioItems);
          }
        } catch (error) {
          console.error('Error fetching portfolio items:', error);
        }
      }
    };

    fetchPortfolioItems();
  }, [state.selectedPortfolio, getPortfolios]);

  const handleDeleteItem = async (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);

    const portfolioName = state.portfolios[state.selectedPortfolio].portfolioName;
    try {
      await updatePortfolio(state.selectedPortfolio, { items: updatedItems, portfolioName });
      console.log('Portfolio updated successfully');
    } catch (error) {
      console.error('Error updating portfolio:', error);
    }
  };

  const handleUpdateShares = async (index, shares) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, shares } : item
    );
    setItems(updatedItems);

    const portfolioName = state.portfolios[state.selectedPortfolio].portfolioName;
    try {
      await updatePortfolio(state.selectedPortfolio, { items: updatedItems, portfolioName });
      console.log('Portfolio updated successfully');
    } catch (error) {
      console.error('Error updating portfolio:', error);
    }
  };

  return (
    <div>
      <h2>Portfolio Items</h2>
      {items.length === 0 ? (
        <p>No items in this portfolio.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Ticker</th>
              <th>Shares</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.ticker}</td>
                <td>
                  <input
                    type="number"
                    value={item.shares}
                    onChange={(e) =>
                      handleUpdateShares(index, parseInt(e.target.value, 10))
                    }
                  />
                </td>
                <td>
                  <button onClick={() => handleDeleteItem(index)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PortfolioItems;
