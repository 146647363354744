import React from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation

const brokerages = [
  {
    name: 'Schwab',
    description: 'Schwab provides a wide range of investment options with a focus on no-fee accounts and ETFs.',
    route: '/broker/schwab-onboarding' // Route for Schwab onboarding
  },
  {
    name: 'Fidelity',
    description: 'Fidelity is a leader in retirement services, offering low fees, and strong customer service.',
    route: '/broker/fidelity-onboarding' // Route for Fidelity onboarding
  },
  {
    name: 'E*TRADE',
    description: 'E*TRADE offers powerful trading tools for active traders, with a user-friendly mobile platform.',
    route: '/broker/etrade-onboarding' // Route for E*TRADE onboarding
  },
  {
    name: 'TD Ameritrade',
    description: 'TD Ameritrade is known for its extensive research tools and commission-free trades.',
    route: '/broker/tdameritrade-onboarding' // Route for TD Ameritrade onboarding
  },
  {
    name: 'Robinhood',
    description: 'Robinhood provides commission-free stock trading, particularly popular among millennial investors.',
    route: '/broker/robinhood-onboarding' // Route for Robinhood onboarding
  },
  {
    name: 'Interactive Brokers',
    description: 'Interactive Brokers is best suited for active traders with competitive pricing and robust tools.',
    route: '/broker/interactivebrokers-onboarding' // Route for Interactive Brokers onboarding
  }
];

const Brokerages = () => {
  const navigate = useNavigate(); // Use React Router's navigate function

  const handleClick = (route) => {
    navigate(route); // Navigate to the respective brokerage's onboarding form
  };

  return (
    <div className="brokerages-container">
      {brokerages.map((brokerage, index) => (
        <div
          key={index}
          className="brokerage-box"
          onClick={() => handleClick(brokerage.route)} // Set click event to navigate
          style={{ cursor: 'pointer' }} // Make the div look clickable
        >
          <h3>{brokerage.name}</h3>
          <p>{brokerage.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Brokerages;
