import { forwardRef, useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingIcon from '../infrastructure/loadingicon'; // Import the LoadingIcon component

const StockChart = (props) => {
  const [closePrices, setClosePrices] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  // Calls the Flask API for the close price data
  const fetchCloseData = async (search) => {
    try {
      const response = await fetch(
        `https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com/Stock/${search}/history/5y`
      );
      const data = await response.json();
      setClosePrices(data);

      // Set initial display to last one year
      const endOfArray = Object.keys(data).length;
      const currentDate = Object.keys(data)[endOfArray - 1];

      const startDate = new Date(currentDate);
      startDate.setFullYear(startDate.getFullYear() - 1);

      const initialData = Object.entries(data)
        .filter(([date]) => new Date(date) >= startDate)
        .map(([date, values]) => ({ date, Close: values.Close }));

      setFilteredData(initialData);
    } catch (e) {
      console.log(e);
      setClosePrices([]);
      setFilteredData([]);
    } finally {
      setLoading(false); // Set loading to false after the fetch operation
    }
  };

  // useEffect calls the API fetch function once upon search prop change
  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    fetchCloseData(props.stock);
  }, [props.stock]);

  // Handles button clicks
  const handleButtonClick = (period) => {
    const endOfArray = Object.keys(closePrices).length;
    const currentDate = Object.keys(closePrices)[endOfArray - 1];
    const startDate = new Date(currentDate);

    switch (period) {
      case '1w':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case '1m':
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case '6m':
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case '1y':
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case '5y':
        startDate.setFullYear(startDate.getFullYear() - 5);
        break;
      default:
        break;
    }

    // Filter and format the data
    const formattedData = Object.entries(closePrices)
      .filter(([date]) => new Date(date) >= startDate)
      .map(([date, values]) => ({ date, Close: values.Close }));

    setFilteredData(formattedData);
  };

  // Returns the stock chart and the ticker searched for
  return (
    <div className="stock-chart">
      <div>
        <div className="stock-chart-buttons">
          <button onClick={() => handleButtonClick('1w')}>1w</button>
          <button onClick={() => handleButtonClick('1m')}>1m</button>
          <button onClick={() => handleButtonClick('6m')}>6m</button>
          <button onClick={() => handleButtonClick('1y')}>1y</button>
          <button onClick={() => handleButtonClick('5y')}>5y</button>
        </div>
        {loading ? ( // Conditional rendering for loading state
          <LoadingIcon /> // Show loading icon while loading
        ) : (
          <ResponsiveContainer aspect={1.5}>
            <LineChart width={props.width} height={props.height} data={filteredData}>
              <XAxis dataKey="date" />
              <YAxis domain={['dataMin - 5', 'dataMax + 5']} hide={true} tickCount={0} />
              <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} contentStyle={{ backgroundColor: 'gray' }} />
              <Line type="monotone" dataKey="Close" stroke="#61dafb" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default StockChart;
