import React, { useState, useEffect } from "react";
import PortfolioFunctionButton from "../portfolio/portfoliobutton";
import "../styles/searchbar.style.css";

const AutoCompleteSearch = ({ query, onSuggestionClick, companyTickers }) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const MAX_SUGGESTIONS = 5;

    const filteredSuggestions = Object.keys(companyTickers)
      .filter(key => companyTickers[key].ticker.toUpperCase().includes(query.toUpperCase()))
      .slice(0, MAX_SUGGESTIONS)
      .map(key => companyTickers[key].ticker);

    setSuggestions(filteredSuggestions);
  }, [query, companyTickers]);

  return (
    <div className="input-container">
      
      {suggestions.length > 0 && (
        <li className="auto-complete-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => onSuggestionClick(suggestion)}>
              {suggestion}
              <div className="add-button">

              <PortfolioFunctionButton addItem={suggestion} />
              </div>
            </li>
          ))}
        </li>
      )}
    </div>
  );
};

export default AutoCompleteSearch;
