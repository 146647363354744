import React from 'react';
import { Link } from 'react-router-dom';

const ClientPage = () => {
    return (
        <div className="client-page">
            <h2>What would you like to do today?</h2>
            <div className="button-container">
                <button className="large-button">          
                    <Link to='/clients/onboarding'>Onboard A Client</Link>
                </button>
                <button className="large-button">
                 <Link to='/clientpage/clients'>View Clients</Link>

                </button>
                <button className="large-button">
                    <Link to='/clients/analytics'>View Client Analytics</Link>

                </button>
            </div>
        </div>
    );
};

export default ClientPage;
