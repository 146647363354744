import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

// Register the SankeyController and Flow for Chart.js
Chart.register(SankeyController, Flow);

// Constants for color values
const INCOME_COLOR = '#517664'; // Golf green for income and savings
const EXPENSE_COLOR = '#581908'; // Crimson for expenses

// Example data for the Sankey chart
const sankeyData = [
  { from: 'Income', to: 'Salary', flow: 4000 },
  { from: 'Income', to: 'Freelancing', flow: 1500 },
  { from: 'Income', to: 'Investments', flow: 600 },
  { from: 'Income', to: 'Total Income', flow: 6100 }, // Total Income
  { from: 'Total Income', to: 'Taxes', flow: 1200 },
  { from: 'Total Income', to: 'Rent/Mortgage', flow: 2000 },
  { from: 'Total Income', to: 'Groceries/Food', flow: 500 },
  { from: 'Total Income', to: 'Utilities', flow: 300 },
  { from: 'Total Income', to: 'Transportation', flow: 400 },
  { from: 'Total Income', to: 'Entertainment', flow: 300 },
  { from: 'Total Income', to: 'Savings', flow: 2100 } // Remaining savings
];

// Function to determine color based on flow type
const getColor = (flow) => {
  // Define the colors based on the flow's destination
  if (flow === 'Income' || flow === 'Savings') {
    return INCOME_COLOR; // For income and savings
  } else {
    return EXPENSE_COLOR; // For all other expenses
  }
};

// Tooltip label callback
const tooltipLabelCallback = (context) => {
  return `${context.raw.from} → ${context.raw.to}: $${context.raw.flow}`;
};

const SankeyChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Create the Sankey chart only on mount
    chartInstanceRef.current = new Chart(ctx, {
      type: 'sankey',
      data: {
        datasets: [{
          label: 'Client Financial Flow',
          data: sankeyData,
          colorFrom: (c) => getColor(c.from), // Get color based on "from" node
          colorTo: (c) => getColor(c.to), // Get color based on "to" node
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1,
          hoverBorderColor: '#000',
          hoverBorderWidth: 2,
        }]
      },
      options: {
        layout: {
          padding: 10
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: tooltipLabelCallback
            }
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            display: false
          },
          y: {
            display: false
          }
        }
      }
    });

    // Cleanup function to destroy the chart instance on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div>
      <h2>Client Financial Overview</h2>
      <div style={{ height: '400px', width: '100%' }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default SankeyChart;
