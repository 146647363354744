import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LoadingIcon from '../infrastructure/loadingicon'; // Import the LoadingIcon component

// Register Chart.js components for the Bar chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DividendsChart = (props) => {
  const [dividends, setDividends] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  // Base API URL
  const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

  const fetchDividends = async (search) => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/Stock/${search}/dividends`
      );
      const data = await response.json();
      console.log(data);
      setDividends(data);
    } catch (e) {
      console.log(e);
      setDividends({});
    } finally {
      setLoading(false); // Set loading to false after the fetch operation
    }
  };

  const setChart = () => {
    // Filter dividends for the last 7 years
    const currentDate = new Date();
    const lastSevenYears = currentDate.getFullYear() - 7;
    const filteredDividends = Object.entries(dividends)
      .filter(([date]) => {
        const year = parseInt(date.split("/")[2]);
        return year >= lastSevenYears;
      })
      .reduce((obj, [date, value]) => {
        obj[date] = value;
        return obj;
      }, {});

    const data = {
      labels: Object.keys(filteredDividends),
      datasets: [
        {
          label: "Dividends",
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: Object.values(filteredDividends).map((item) => item["Dividends"]),
        },
      ],
    };
    return data;
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    fetchDividends(props.stock);
  }, [props.stock]);

  return (
    <div>
      {loading ? ( // Conditional rendering for loading state
        <LoadingIcon /> // Show loading icon while loading
      ) : (
        Object.keys(dividends).length > 0 ? ( // Check if there are any dividends
          <Bar data={setChart()} options={options} width={650} /> // Show chart when loaded
        ) : (
          <p>{props.stock} does not currently pay a dividend.</p> // Message for no dividends
        )
      )}
    </div>
  );
};

export default DividendsChart;
