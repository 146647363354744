import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../components/styles/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    </head>
      {/* Hamburger Icon */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Overlay */}
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={toggleMenu}></div>

      {/* Navbar */}
      <div className={`nav-bar ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/rates">Rates</Link>
          </li>
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/clientpage">Clients</Link>
          </li>
          <li>
            <Link to="/login">Login/Register</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
