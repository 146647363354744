import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LoadingIcon from '../infrastructure/loadingicon'; // Import LoadingIcon component

// Register Chart.js components for the Bar chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// base API URL
const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

const VolumeChart = (props) => {
  const [volData1Y, setVolData1Y] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  // gets the volume values from the volume data use state variable and parses the data
  const getVolumeValues = () => {
    let emptyArray = [];
    for (let k = 0; k < Object.keys(volData1Y).length; k++) {
      let newVal = Object.values(volData1Y)[k].Volume;
      emptyArray.push(newVal);
    }
    return emptyArray;
  };

  // assigns the Volumes variable to the 'getVolumeValues' function
  let Volumes = getVolumeValues();

  // fetches the 1 year volume from the flask API with input (ticker)
  const fetch1yVolume = async (search) => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/volume/1y`);
      const data = await response.json();
      setVolData1Y(data);
    } catch (e) {
      console.log(e);
      setVolData1Y(0);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // function to handle the settings of the chart
  const setChart = () => {
    const data = {
      labels: Object.keys(volData1Y),
      datasets: [
        {
          label: "Volume",
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: Volumes,
        },
      ],
    };
    return data;
  };

  // Chart options
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          display: false,
        },
      },
    },
  };

  // useEffect calls the fetch function upon search prop change
  useEffect(() => {
    fetch1yVolume(props.stock);
  }, [props.stock]);

  return (
    <div>
      {loading ? (
        <LoadingIcon /> // Use the LoadingIcon component while loading
      ) : (
        <Bar data={setChart()} options={options} width={"650"} />
      )}
    </div>
  );
};

export default VolumeChart;
