import React, { useState } from "react";
import UserPool from "./userPool";
import ReactModal from 'react-modal';
import "../styles/Signup.style.css";
import Confirm from './confirmationSignin'; // Import the Confirm component

ReactModal.setAppElement('#root'); // For accessibility

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setError("");
        setSuccess("");

        if (!email || !password) {
            setError("Email and Password are required.");
            return;
        }

        UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                handleError(err);
            } else {
                setSuccess("Signup successful! Please check your email for verification.");
                setIsModalOpen(true); // Open the modal after successful signup
                console.log(data);
            }
        });
    };

    const handleError = (err) => {
        let errorMessage = "An error occurred during signup.";
        if (err.code) {
            switch (err.code) {
                case "InvalidPasswordException":
                    errorMessage = "Password does not meet the requirements. Please ensure it is at least 8 characters long, includes an uppercase letter, a lowercase letter, a number, and a special character.";
                    break;
                case "InvalidParameterException":
                    errorMessage = "The email or password is invalid. Please ensure the email is correctly formatted.";
                    break;
                case "UsernameExistsException":
                    errorMessage = "An account with this email already exists.";
                    break;
                default:
                    errorMessage = err.message || errorMessage;
            }
        }
        setError(errorMessage);
    };

    return (
        <div className="signup-container">
            <form onSubmit={onSubmit} className="signup-form">
                <h2>Sign Up</h2>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                    />
                </div>

                <button type="submit" className="signup-button">Signup</button>
            </form>

            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            {/* Modal for confirmation */}
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Confirm User"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Confirm Your Account</h2>
                <Confirm />
                <button onClick={() => setIsModalOpen(false)} className="close-button">Close</button>
            </ReactModal>
        </div>
    );
};

export default Signup;
