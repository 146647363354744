import "../components/styles/FrontPage.style.css"
// import "../components/styles/global.style.css"


const FrontPage = () => {
    return (
        <div className="sales-page">
          {/* Header Section */}
          <header className="header">
            <div className="header-content">
              <h1>---</h1>
              <p>
                Transform your advisory with reliable, actionable insights, and tailored solutions that 
                empower advisors to deliver exceptional client experiences.
              </p>
              <a href="#features" className="cta-button">Discover Our Platform</a>
            </div>
          </header>
    
          {/* Key Benefits Section */}
          <section id="features" className="benefits-section">
            <h2>Why Choose Us?</h2>
            
            {/* Benefit 1: Data Accuracy and Reconciliation */}
            <div className="benefit">
              <h3>Data Accuracy and Reconciliation</h3>
              <p>
                Ensure your clients receive accurate, up-to-date investment data from multiple sources. 
                Our platform automates reconciliation, minimizing manual checks and maximizing 
                reliability so you can make data-driven decisions with confidence.
              </p>
            </div>
    
            {/* Benefit 2: AI-Driven Insights and Predictive Analysis */}
            <div className="benefit">
              <h3>AI-Driven Insights and Predictive Analysis</h3>
              <p>
                Leverage AI to move beyond simple data aggregation. Our platform provides proactive, 
                predictive insights tailored to your clients' portfolios, helping you anticipate trends 
                and act on emerging opportunities.
              </p>
            </div>
    
            {/* Benefit 3: Seamless Integration with Popular Tools */}
            <div className="benefit">
              <h3>Seamless Integration with Your Favorite Tools</h3>
              <p>
                Easily integrate with the tools you already use. With pre-built connectors to leading 
                CRM, portfolio management, and financial planning tools, we ensure a smooth setup that 
                enhances—not disrupts—your workflow.
              </p>
            </div>
    
            {/* Benefit 4: Tailored Client Dashboards */}
            <div className="benefit">
              <h3>Tailored Client Dashboards</h3>
              <p>
                Provide your clients with personalized, co-branded dashboards that reflect their unique 
                financial picture. Our customizable interface enhances transparency and fosters trust, 
                creating a memorable, branded client experience.
              </p>
            </div>
    
            {/* Benefit 5: Real-Time Non-Traditional Financial Data */}
            <div className="benefit">
              <h3>Real-Time Data from Non-Traditional Sources</h3>
              <p>
                Expand your advisory scope by incorporating real-time data from alternative investments, 
                crypto, and other non-traditional financial sources. Gain a holistic view of your 
                clients' wealth for comprehensive advisory.
              </p>
            </div>
          </section>
    
          {/* CTA Section */}
          <section className="cta-section">
            <h2>Ready to Enhance Your Advisory?</h2>
            <p>
              Start delivering insights, reliability, and value to your clients today. 
              Our platform adapts to your needs and grows with you.
            </p>
            <a href="/signup" className="cta-button">Get Started</a>
          </section>
        </div>
      );
  };

    
export default FrontPage