import React, { useState, useEffect } from 'react';
import LoadingIcon from '../infrastructure/loadingicon'; // Import the LoadingIcon component

const BusinessDescription = (props) => {
  const [businessDescription, setBusinessDescription] = useState("Loading...");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  // Base API URL
  const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

  const fetchData = async (search) => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/description`);
      if (!response.ok) {
        throw new Error('Failed to fetch stock information');
      }
      const data = await response.json();

      setBusinessDescription(data);
      setError(null); // Reset error state on successful data fetch
    } catch (error) {
      console.error('Error fetching stock information:', error);
      setBusinessDescription(null); // Set BusinessDescription to null on error
      setError(error.message || 'An error occurred while fetching data');
    } finally {
      setLoading(false); // Set loading to false after the fetch operation
    }
  };

  useEffect(() => {
    fetchData(props.stock);
  }, [props.stock]);

  return (
    <div>
      <h2>Business Description</h2>
      <div>
        {loading ? ( // Conditional rendering for loading state
          <LoadingIcon /> // Show loading icon while loading
        ) : error || businessDescription === null ? (
          <p>Not Applicable</p>
        ) : (
          <p>{businessDescription}</p>
        )}
      </div>
    </div>
  );
};

export default BusinessDescription;
